.paginationWrapper {
    display: flex;
}

.paginationBlock {
    border-radius: 16px;
    border: 2px solid #DEE6F4;
    width: 60px;
    height: 60px;
    color: #2A3559;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    margin-right: 6px;
}

.paginationBlock_active {
    color: #ffffff;
    background-color: #2A82B3;
}

.paginationArrow,
.paginationFiller {
    display: flex;
    align-items: center;
    margin-left: 6px;
    margin-right: 6px;
}

