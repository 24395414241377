.iconButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
}

.iconButtonWrapper_fill {
    border-radius: 16px;
    background-color: #2A82B3;
    border: none;
    padding: 13px 16px;
}

.iconButtonWrapper_disabled {
    opacity: 0.5;
    pointer-events: none;
}
