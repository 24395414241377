.settingsModalWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    top: 0;
    left: 0;
    z-index: 9999999;
}

.settingsHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
}

.settingsOptionWrapper {
    display: flex;
    width: 600px;
    align-items: center;
    margin-bottom: 30px;
}

.settingsOptionLabel {
    font-weight: 600;
    color: #182039;
    min-width: 175px;
}

.settings_ttsSpeakingRateInput {
    background-color: #EDEEF4;
    border-radius: 16px;
    font-size: 16px;
    line-height: 19px;
    color: #141C32;
    padding: 22px 20px;
    border: none;
    width: -webkit-fill-available;
    width: -moz-available;  
    width: fill-available;
    width: 50px;
}

.settings_ttsSpeakingRateInput:focus {
    outline: none;
}

.settingsSubmitButton {
    position: absolute;
    bottom: 200px;
    right: 20px;
}

.settingsModalBody {
    padding: 20px 30px;
}
