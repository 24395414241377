.intentRowWrapper {
    background-color: #EDEEF4;
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 16px;
    display: flex;
    margin-bottom: 20px;
    position: relative;
}

.intentRowName,
.intentsListCellData {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

.intentRowName,
.intentsListCellData {
    color: #2A3559;
}

.intentsListCellIndex {
    color: #B9C0D7;
    margin-right: 14px;
    line-height: 24px;
    font-size: 20px;
    font-weight: 600;
}

.intentRowName {
    width: 20%;
    padding-left: 29px;
    overflow: hidden;
    position: relative;
}

.intentsListCellWrapper {
    width: 37%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
}

.intentsListCellWrapper_wrap {
    white-space: pre-wrap;
}


.intentRowCell_blur {
    width: 70px;
    height: 30px;
    position: absolute;
    top: 0;
    right: -1px;
    transform: rotate(180deg);
    background: linear-gradient(90deg, #EDEEF4 27.14%, rgba(237, 238, 244, 0) 90.71%);
}

.intentRowArrow {
    margin-right: 16px;
    display: flex;
}

.intentListCellItem {
    margin-top: 16px;
}

.intentRowActionWrapper {
    display: flex;
    align-items: center;
    width: 5%;
    height: 26px;
}

.intentRowTooltip {
    z-index: 99;
    position: absolute;
    top: 60px;
    left: 20px;
}

.intentListCellItemValues {
    display: flex;
}