.rangeSliderWrapper {
    width: 100%;
    display: flex;
    align-items: center;
}

.rangeSliderLabel {
    color: #182039;
    font-weight: 600;
}
