.wrapper {
    display: flex;
    flex-direction: column;
}

.inputTextAreaWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.inputTextArea {
    background-color: #EDEEF4;
    border-radius: 20px;
    border: none;
    resize: none;
    padding: 24px 12px 24px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    width: 100%;
    color: #141C32;
}

.inputTextArea_error {
    border: 2px solid #FF9292;
}

.inputTextArea:focus {
    outline: none;
}

.inputAddButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.inputError {
    color: #FF9292;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin-top: 8px;
}

