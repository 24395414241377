.buttonWrapper {
    border-radius: 16px;
    padding: 16px 24px;
    line-height: 19px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    display: flex;
    justify-content: center;
}

.buttonWrapper_primary {
    background-color: #2A82B2;
    color: #FFFFFF;
    min-width: 130px;
}

.buttonWrapper_secondary {
    color:  #141C32;
    background: none;
}

.buttonWrapper_error {
    background-color: #E06169;
    color: #FFFFFF;
}

.buttonWrapper_disabled {
    opacity: 0.5;
    pointer-events: none;
}

.buttonIcon {
    margin-right: 16px;
}