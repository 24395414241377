.inputFieldWrapper {
    width: 100%;
}

.inputField {
    background-color: #EDEEF4;
    border-radius: 16px;
    font-size: 16px;
    line-height: 19px;
    color: #141C32;
    padding: 22px 20px;
    border: none;
    width: -webkit-fill-available;
    width: -moz-available;  
    width: fill-available;
}

.inputField:focus {
    outline: none;
}

.inputField_error {
    border: 2px solid #FF9292;
}

.inputFieldErrorMessage {
    color: #FF9292;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin-top: 8px;
}