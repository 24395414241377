.tooltipText {
    border-radius: 16px;
    background-color: #13283A;
    box-shadow: 0px 6px 87px rgba(0, 0, 0, 0.12);
    color: #FFFFFF;
    font-size: 16px;
    line-height: 19px;
    padding: 20px;
}

.tooltipWrapper {
    position: relative;
}

.tooltipTriangle {
    position: absolute;
    bottom: 22px;
}