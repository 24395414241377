.intentModalWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    top: 0;
    left: 0;
    z-index: 9999999;
}

.intentHeaderWrapper {
    display: flex;
}

.intentHeader {
    font-size: 14px;
    line-height: 16px;
    color: #9097AF;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.intentHeader:first-child,
.intentBody > div:first-child {
    width: 35%;
    margin-right: 30px;
}

.intentHeader:nth-child(2),
.intentBody > div:nth-child(2) {
    flex: 1;
}

.intentBody {
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    height: 700px;
}

.intentListItemWrapper {
    margin-top: 24px;
}

.buttonWrapper {
    display: flex;
    position: absolute;
    bottom: 60px;
    right: 90px;
}

.intentTypeSelection {
   width: 100%;
   text-align: end;
   margin-bottom: 24px;
}