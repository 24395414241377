.modalWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    overflow: auto;
    background-color: rgba(158, 164, 175, 0.48);
}

.modal {
    width: 500px;
    background-color: #ffffff;
    border-radius: 29px;
    padding: 40px 24px;
    margin: 160px auto;
    color: #141C32;
}

.modalHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    }

.modalHeaderTitle {
    color: #141C32;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    max-width: 350px;
}

.buttonWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.modalChildrenWrapper {
    margin-bottom: 32px;
}
