.listItemWrapper {
    border-radius: 20px;
    border: 2px solid #DEE6F4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
}

.listItemWrapper > span {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #141C32;
}