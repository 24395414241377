.searchBarWrapper {
    border-radius: 16px;
    background-color: #EDEEF4;
    padding: 20px 18px;
    width: 360px;
    display: flex;
}

.searchBarText {
    color: #141C32;
    font-size: 16px;
    line-height: 25px;
    border: none;
    background: none;
    margin-left: 8px;
    width: 100%;
}

.searchBarText:focus {
    outline: none;
}

.searchBarText::placeholder{
    color: #9097AF;
}

