.stopwordList {
    display: flex;
    flex-wrap: wrap;
}

.stopwordItem {
    width: 275px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #EDEEF4 ;
    border-radius: 16px;
    padding: 4px 12px;
    position: relative;
    margin-right: 16px;
    margin-bottom: 16px;
    color: #182039;
    font-weight: 600;
}

.stopwordsHeader {
    display: flex;
    margin-bottom: 16px;
}

.stopwordsHeader > button:last-child {
    margin-left: auto;
}