.dropdownMenuWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 15px;
}

.dropdownMenu {
    border-radius: 12px;
    background: #ffffff;
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 24px 60px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.dropdownMenuAction {
    display: flex;
    padding: 14px;
    width: 200px;
    align-items: center;
}

.dropdownMenuAction:hover{
    background: #EDEEF4;
    border-radius: 12px;
}

.dropdownMenuActionName {
    margin-left: 16px;
}
