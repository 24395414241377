.intentsPage_intentsTableHeader {
    color: #9097AF;
    font-size: 16px;
    line-height: 27px;
    font-weight: 700;
}

.intentsPage_intentsTableHeader:first-child {
    padding-left: 29px;
}

.intentsPage_header {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #182039;
}

.intentsPage_intentsTable {
    margin-top: 40px;
    position: relative;
}

.intentsPage_intentsTableHeaderWrapper {
    display: flex;
    margin-bottom: 8px;
}

.intentsPage_intentsTableHeader:nth-child(1) {
    width: 20%;
}

.intentsPage_intentsTableHeader:nth-child(2) {
    width: 37%;
}

.intentsPage_intentsTableHeader:nth-child(3) {
    width: 37%;
}

.intentsPage_intentsTableHeader:nth-child(4) {
    width: 5%;
}

.intentsPage_noResultsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.intentsPage_noResultsIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDEEF4;
    border-radius: 16px;
    width: 56px;
    height: 56px;
    padding: 24px 26px;
}

.intentsPage_noResultsHeader {
    color: #130F2D;
    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
    text-align: center;
    margin-top: 12px;
}

.intentsPage_noResultsText {
    color: #9097AF;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
}

.intentsPage_pageHeaderButtonWrapper {
    display: flex;
}

.intentsPage_pageHeaderButtonWrapper > button:not(:last-child){
    margin-right: 8px;
}

.intentsPage_noIntents {
    color: #9097AF;
    margin-left: 29px;
    font-weight: 600;
}

.intentsPage_filterWrapper {
    display: flex;
    align-items: center;
}

.intentsPage_filterWrapper > div:nth-child(2){
    margin-left: 32px;
    margin-right: 72px;
}

.intentsPage_filterWrapper > button {
    margin-left: auto;
}

.listTabs {
    display: flex;
    padding-left: unset;
    background-color: #EDEEF4 ;
    align-items: flex-end;
    margin-top: 48px;
    margin-bottom: 48px;
}

.listTab {
    width: 150px;
    height: 40px;
    border-radius: 8px 8px 0 0;
    border: 1px solid #9097AF;
    list-style-type: none;
    background-color: #EDEEF4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    color: #182039;
    font-weight: 600;
}


.listTab_active {
    background-color: #FFFFFF;
    border-bottom: none;
    height: 46px;
}
