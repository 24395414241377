.notificationWrapper {
    display: flex;
    background: #13283A;
    color: white;
    border-radius: 20px;
    padding: 5px;
    width: 330px;
    align-items: center;
    position: absolute;
    right: 60px;
    bottom: 50px;
    z-index: 9999; 
}

.notificationIcon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px
}

